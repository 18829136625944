import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import Main from './Main';
import Header from './header';
import NavVertical from './nav/NavVertical';
// redux for notes
import { dispatch as dispatchAction, useSelector } from '../../redux/store';
import { startLoading } from '../../redux/slices/notes';
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { note } = useSelector((slice) => slice.notes);
  const { pathname } = useLocation();

  const handleOpen = () => {
    if (note && pathname === '/document') dispatchAction(startLoading());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
