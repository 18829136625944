import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { Buffer } from 'buffer';
import { removeReCaptcha } from '../utils/recaptcha';
import { usePostHog } from 'posthog-js/react';
// import { getCookie } from './../utils/CookieUtils';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
import { setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';

// import { socket } from './../websocket/socket';

// ----------------------------------------------------------------------

// The Types enum contains string values for four different types of actions: INITIAL, LOGIN, REGISTER, and LOGOUT.
enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

// The Payload type is an object with keys that correspond to the values in the Types enum.
// The values for each key in Payload are objects that contain information relevant to each action type.
// For example, for the LOGIN action type, there is a user property with a value of type AuthUserType.
type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: { isAuthenticated: boolean };
};

// The ActionsType type is the result of mapping the Payload object to the ActionMapType type, and taking the key of the resulting object.
// It represents a union of all possible actions that can be dispatched in the application.
type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

// The initial state of the application.
const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const posthog = usePostHog();

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      // Checks if the user is authenticated
      if (accessToken) {
        setSession(accessToken);

        // Get user data
        const response = await axios.get('/api/me', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });

        const user = response.data;

        if (user.is_verified) {
          if (user.roles.includes('superadmin')) {
            localStorage.setItem('super_admin', 'true');
            sessionStorage.setItem('super_admin', 'true');
          }
          localStorage.setItem(
            'market_id',
            user?.market?.id || process.env.REACT_APP_MARKET_DEFAULT
          );
          sessionStorage.setItem(
            'market_id',
            user?.market?.id || process.env.REACT_APP_MARKET_DEFAULT
          );

          // socket.emit('join_notification_room', user?.uid);

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: user.is_verified,
              user,
            },
          });
        } else {
          throw Error(`User is not authenticated`);
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
    // console.log('initialize');
  }, [initialize]);

  const updatePremiumOnboarding = useCallback(async (id: number) => {
    const response = await axios.put('/api/update_premium_onboarding', {
      id: id,
    });
    const user = response.data;
    if (user.is_verified) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: user.is_verified,
          user,
        },
      });
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      throw Error(`User is not authenticated`);
    }
  }, []);

  const updateFreemiumOnboarding = useCallback(async (id: number) => {
    const response = await axios.put('/api/update_freemium_onboarding', {
      id: id,
    });
    const user = response.data;
    if (user.is_verified) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: user.is_verified,
          user,
        },
      });
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      throw Error(`User is not authenticated`);
    }
  }, []);

  const refreshUser = useCallback(async () => {
    const accessToken =
      localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    if (accessToken) {
      // Get user data
      const userResponse = await axios.get('/api/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const user = userResponse.data;

      if (user.is_verified) {
        if (user.roles.includes('superadmin')) {
          localStorage.setItem('super_admin', 'true');
          sessionStorage.setItem('super_admin', 'true');
        }
        localStorage.setItem('market_id', user?.market?.id || process.env.REACT_APP_MARKET_DEFAULT);
        sessionStorage.setItem(
          'market_id',
          user?.market?.id || process.env.REACT_APP_MARKET_DEFAULT
        );

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: user.is_verified,
            user,
          },
        });
      } else {
        throw Error(`User is not authenticated`);
      }
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  // LOGIN
  /** Login by sending a POST request to /api/tokens
   * Passing the username and password in a standard basic authentication header.
   */
  const login = useCallback(async (email: string, password: string, token: string) => {
    const response = await axios.post('/api/tokens', null, {
      headers: {
        Authorization: `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`,
        'g-recaptcha-response': token,
      },
      withCredentials: true,
    });

    const accessToken = response.data;
    // Sets the access token to local storage. if local is not available then adds it to the session storage.
    setSession(accessToken.access_token);
    if (accessToken) {
      // Get user data
      const userResponse = await axios.get('/api/me', {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const user = userResponse.data;

      if (user.is_verified) {
        posthog.identify(user?.uid, { email: user?.email, phone: user?.phone });
        // socket.emit('join_notification_room', user?.uid);
        posthog.capture('user_login', { user_id: user.uid, email: user.email });
        if (user.roles.includes('superadmin')) {
          localStorage.setItem('super_admin', 'true');
          sessionStorage.setItem('super_admin', 'true');
        }
        localStorage.setItem('market_id', user?.market?.id || process.env.REACT_APP_MARKET_DEFAULT);
        sessionStorage.setItem(
          'market_id',
          user?.market?.id || process.env.REACT_APP_MARKET_DEFAULT
        );

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: user.is_verified,
            user,
          },
        });
        removeReCaptcha();
      } else {
        throw Error(`User is not authenticated`);
      }
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  const sendConfirmationEmail = useCallback(async (email: string, token: string) => {
    const response = await axios.post(
      '/api/resendEmail',
      {
        email: email,
      },
      { headers: { 'g-recaptcha-response': token } }
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }, []);

  // Confirmation Freemium
  /** Confirmation by sending a PUT request to /api/tokens/confirm
   *  Confirms freemium users
   */
  const confirmFreemium = useCallback(async (token: string) => {
    const response = await axios.put('/api/tokens/confirm', {
      token: token,
    });

    // Sets the access token to local storage.
    if (response.status === 200) {
      return true;
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return false;
    }
  }, []);

  // Confirmation
  /** Confirmation by sending a PUT request to /api/tokens/confirmation
   * Passing the username, password and confirmPassword in a standard basic authentication header.
   */
  const confirmation = useCallback(
    async (
      email: string,
      password: string,
      confirmPassword: string,
      token: string,
      tokenGoogle: string
    ) => {
      const response = await axios.put(
        '/api/tokens/confirmation',
        {
          email: email,
          password: password,
          confirm: confirmPassword,
          token: token,
        },
        { headers: { 'g-recaptcha-response': tokenGoogle } }
      );

      // Sets the access token to local storage.
      if (response.status === 200) {
        return response.data.message;
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return response.data.message;
      }
    },
    []
  );
  /** Confirmation by sending a PUT request to /api/tokens/confirmation
   * Passing the username, password and confirmPassword in a standard basic authentication header.
   */
  const resetPasswordConfirm = useCallback(
    async (
      email: string,
      password: string,
      confirmPassword: string,
      token: string,
      tokenGoogle: string
    ) => {
      const response = await axios.put(
        '/api/tokens/reset',
        {
          email: email,
          password: password,
          confirm: confirmPassword,
          token: token,
        },
        { headers: { 'g-recaptcha-response': tokenGoogle } }
      );

      // Sets the access token to local storage.
      if (response.status === 204) {
        return true;
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return false;
      }
    },
    []
  );

  // REGISTER AI
  const registerAI = useCallback(
    async (
      email: string,
      password: string,
      confirmPassword: string,
      marketCode: string,
      language: string
    ) => {
      const response = await axios.post(
        '/api/new_user_ai',
        {
          email: email,
          password: password,
          confirm_password: confirmPassword,
          market_code: marketCode,
          language: language,
        }
        // { headers: { 'g-recaptcha-response': token } }
      );

      if (response.status === 201) {
        const responseLogin = await login(email, password, '');
        return true;
      } else {
        return false;
      }
    },
    []
  );

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      firstName: string,
      lastName: string,
      phone: string,
      school: string,
      grade: string,
      highSchoolType: string,
      subject: string[],
      level: string,
      goals: string,
      interests: string[],
      market: string
    ) => {
      const response = await axios.post(
        '/api/new_user',
        {
          email,
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          school: school,
          grade: grade,
          highschooltype: highSchoolType,
          subject: subject,
          level: level,
          goals: goals,
          interests: interests,
          market: market,
        }
        // { headers: { 'g-recaptcha-response': token } }
      );

      if (response.status === 201) {
        return response.data.id;
      } else {
        return {};
      }
    },
    []
  );

  // Password reset
  const passwordReset = useCallback(async (email: string, token: string) => {
    const response = await axios.post(
      'api/tokens/reset',
      {
        email,
      },
      { headers: { 'g-recaptcha-response': token } }
    );

    // Sets the access token to local storage.
    if (response.status === 204) {
      return true;
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return false;
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    const logoutServer = async () => {
      await axios.delete('/api/tokens');
    };

    logoutServer().then(() => {
      // Delete access token from local storage and axios default header
      setSession(null);
      dispatch({
        type: Types.LOGOUT,
        payload: {
          isAuthenticated: false,
        },
      });
    });

    // to unlink any future events made on that device with that user, we reset.
    posthog.reset();
  }, []);

  const updateLanguage = useCallback(async (id: number, newLang: string) => {
    const response = await axios.put('/api/update_language', {
      id: id,
      language: newLang,
    });
    const user = response.data;
    if (user.is_verified) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: user.is_verified,
          user,
        },
      });
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      throw Error(`User is not authenticated`);
    }
  }, []);
  const updateMarket = useCallback(async (id: number, newMarket: string) => {
    const response = await axios.put('/api/update_market', {
      id: id,
      new_market: newMarket,
    });
    const user = response.data;
    if (user.is_verified) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: user.is_verified,
          user,
        },
      });
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      throw Error(`User is not authenticated`);
    }
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      updateLanguage,
      updateMarket,
      sendConfirmationEmail,
      resetPasswordConfirm,
      confirmation,
      passwordReset,
      loginWithGoogle: noop,
      loginWithGithub: noop,
      loginWithTwitter: noop,
      register,
      registerAI,
      refreshUser,
      updatePremiumOnboarding,
      updateFreemiumOnboarding,
      confirmFreemium,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      updateLanguage,
      updateMarket,
      updatePremiumOnboarding,
      updateFreemiumOnboarding,
      logout,
      sendConfirmationEmail,
      register,
      registerAI,
      refreshUser,
      confirmation,
      confirmFreemium,
      passwordReset,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
