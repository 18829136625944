import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { ITeacherKpiState } from 'src/@types/teacher-kpi';

const initialState: ITeacherKpiState = {
  isLoading: false,
  isLoadingStudents: false,
  error: null,
  hours: null,
  earnings: null,
  totalStreak: null,
  studentStreaks: null,
  inactiveStudents: [],
};
const slice = createSlice({
  name: 'teacherKpi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // START LOADING Students
    startLoadingStudents(state) {
      state.isLoadingStudents = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }, // GET Lesosns
    getHoursSuccess(state, action) {
      state.isLoading = false;
      state.hours = action.payload;
    },
    sentInactiveStudentNotification(state, action) {
      state.isLoadingStudents = false;
      state.error = null;
      state.inactiveStudents = state.inactiveStudents.map((student) => {
        if (parseInt(student.id || '') === parseInt(action.payload.id)) {
          return action.payload;
        }
        return student;
      });
    },
    getEarningsSuccess(state, action) {
      state.isLoading = false;
      state.earnings = action.payload;
    },
    getTotalStreakSuccess(state, action) {
      state.isLoading = false;
      state.totalStreak = action.payload;
    },
    getStudentStreaksSuccess(state, action) {
      state.isLoading = false;
      state.studentStreaks = action.payload;
    },
    getInactiveStudentsSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingStudents = false;

      state.inactiveStudents = action.payload;
    },
  },
});

export default slice.reducer;

export function getHoursTaught(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/hours-taught/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Hours taught not found');
      } else {
        dispatch(slice.actions.getHoursSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getEarnings(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/earnings/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Earnings taught not found');
      } else {
        dispatch(slice.actions.getEarningsSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTotalStreak(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/total-teaching-streak/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Total streak not found');
      } else {
        dispatch(slice.actions.getTotalStreakSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getStudentStreaks(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/teaching-streak/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Student streaks not found');
      } else {
        dispatch(slice.actions.getStudentStreaksSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function fetchInactiveStudents(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoadingStudents());
    try {
      const url = `/api/fetch-inactive-students/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Inactive students not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getInactiveStudentsSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function teacherProblemNotification(
  id: string | number,
  description: string,
  student_id: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/teacher-problem-notification`;
      const response = await axios.post(
        url,
        { teacher_id: id, description: description, student_id: student_id },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        console.log(response);
        dispatch(slice.actions.sentInactiveStudentNotification(response.data));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
