import {
  Dialog,
  Button,
  Stack,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  Box,
  RadioGroup,
  DialogTitle,
  Typography,
  Link,
  useTheme,
  IconButton,
  CircularProgress,
  Chip,
} from '@mui/material';
import { alpha } from '@mui/material';
import { m } from 'framer-motion';

import { useState } from 'react';
import Latex from 'react-latex-next';

import { IExercises } from 'src/@types/ai';
import { useLocales } from 'src/locales';
import Iconify from '../../../components/iconify';
import LoadingDots from '../../../components/loading-dots';
import useResponsive from 'src/hooks/useResponsive';

type Props = {
  exercises: IExercises[];
  currentQuestion: string;
  open: boolean;
  onClose: (value: boolean) => void;
  isLoading: boolean;
  generateExercises: (exercises: IExercises[], prompt: string, difficulty: string) => void;
  handleQuestion: (value: string, dontGenerateExecises?: boolean) => void;
};

const shuffleArray = (answersArray: string[]) => {
  for (let i = answersArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [answersArray[i], answersArray[j]] = [answersArray[j], answersArray[i]]; // Swap elements
  }
  return answersArray;
};

export default function AIExercises({
  exercises,
  currentQuestion,
  open,
  isLoading,
  onClose,
  handleQuestion,
  generateExercises,
}: Props) {
  const [selectedExercise, setSelectedExercise] = useState<IExercises>(exercises[0]);
  const [answerArray, setAnswersArray] = useState<string[]>(
    shuffleArray([
      exercises[0].correct_answer,
      exercises[0].wrong_answer_1,
      exercises[0].wrong_answer_2,
      exercises[0].wrong_answer_3,
    ])
  );
  const isMobile = !useResponsive('up', 1200);
  // console.log(exercises);
  const [currentExerciseIndex, setSelectedExerciseIndex] = useState(0);
  const [amountCorrect, setAmountCorrect] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

  const { translate } = useLocales();
  const theme = useTheme();
  const handleGenerateExercises = (difficulty: string) => {
    generateExercises(exercises, currentQuestion, difficulty);
    setSelectedExercise(exercises[0]);
    setSelectedExerciseIndex(0);
    setSelectedAnswer(null);
  };

  const confirmQuestionAnswer = (answer: string) => {
    setSelectedAnswer(null); // This line might seem redundant but ensures the UI has time to update
    // Use a timeout to ensure the UI has time to process the deselection before selecting a new answer
    setTimeout(() => {
      setSelectedAnswer(answer);
    }, 0);
    if (selectedExercise.correct_answer === answer) {
      setAmountCorrect(amountCorrect + 1);
    }
    return selectedExercise.correct_answer === answer;
  };

  const getColorForAnswer = (answer: string): 'default' | 'success' | 'error' => {
    if (!selectedAnswer) return 'default';
    return answer === selectedExercise.correct_answer ? 'success' : 'error';
  };

  return (
    <>
      {!isLoading ? (
        <>
          {/* Results box when finished with CTAs */}
          {selectedAnswer !== null && exercises.length === currentExerciseIndex + 1 && (
            <ResultsBox
              amountCorrect={amountCorrect}
              exercisesLength={exercises.length}
              onClose={onClose}
              handleGenerateExercises={handleGenerateExercises}
            />
          )}

          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {isMobile ? (
              <>
                <Button variant="outlined" size="small" onClick={() => onClose(false)}>
                  <Iconify icon={'ph:arrow-left'}></Iconify>
                </Button>
              </>
            ) : (
              <Box>
                <Typography variant="h5">
                  <Latex>{selectedExercise.exercise || ''}</Latex>
                </Typography>
              </Box>
            )}

            <Box>
              {isMobile ? (
                <Stack
                  direction={'row'}
                  spacing={1}
                  justifyContent="space-evenly"
                  alignContent={'center'}
                >
                  <Typography>{`${translate('ai.exercises.exercises')}`}</Typography>
                  <Chip label={`${currentExerciseIndex + 1} / ${exercises.length}`} />
                </Stack>
              ) : (
                <Typography variant="subtitle2">
                  {`${translate(`ai.exercises.question`)} ${currentExerciseIndex + 1} ${translate(
                    `ai.exercises.of`
                  )} ${exercises.length}`}
                </Typography>
              )}
            </Box>
          </DialogTitle>

          <DialogContent>
            {isMobile && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  justifyItems: 'center',
                  alignContent: 'center',
                  width: '100%',
                  mb: 2,
                }}
              >
                <Typography variant="h5">
                  <Latex>{selectedExercise.exercise || ''}</Latex>
                </Typography>
              </Box>
            )}

            <FormControl sx={{ width: '100%', overflow: 'visible', mb: 1 }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="quiz"
                value={selectedAnswer} // Controlled component approach
                onChange={(e) => {
                  confirmQuestionAnswer(e.target.value);
                }}
                sx={{ gap: 2, mt: 0, overflow: 'visible' }}
              >
                {answerArray.map((answer, index) => (
                  <Box key={index} sx={{ overflow: 'visible' }}>
                    <FormControlLabel
                      disabled={selectedAnswer !== null}
                      value={answer}
                      control={<Radio size="medium" sx={{ color: theme.palette.text.primary }} />}
                      label={<Latex>{answer}</Latex>}
                      sx={{
                        width: '100%',
                        borderRadius: theme.palette.borders.borderRadius,
                        border: theme.palette.borders.border,
                        boxShadow: theme.palette.borders.boxShadow,
                        padding: 1,
                        color: theme.palette.common.black,
                        '& .MuiRadio-root': {
                          color: theme.palette.common.black,
                        },
                        overflow: 'visible',
                        backgroundColor:
                          getColorForAnswer(answer) === 'success'
                            ? theme.palette.success.lighter
                            : getColorForAnswer(answer) === 'error'
                            ? theme.palette.error.lighter
                            : theme.palette.groundingGrey.main,
                        '&:hover': {
                          backgroundColor:
                            getColorForAnswer(answer) === 'default'
                              ? theme.palette.action.hover
                              : undefined,
                        },
                        '& .MuiFormControlLabel-label': {
                          color: theme.palette.common.black,
                          fontSize: '1.05rem',
                        },
                      }}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Link
                sx={{ cursor: 'pointer' }}
                color="textPrimary"
                onClick={() => {
                  handleQuestion(
                    `${translate('ai.exercises.sendExplain')} ${selectedExercise?.exercise}`,
                    true
                  );
                  onClose(false);
                }}
              >
                <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                  {String(translate('ai.exercises.explain'))}
                </Typography>
              </Link>

              {selectedAnswer !== null && exercises.length !== currentExerciseIndex + 1 && (
                <Button
                  color="primary"
                  style={{
                    border: theme.palette.borders.border,
                    boxShadow: theme.palette.borders.boxShadow,
                    borderRadius: theme.palette.borders.borderRadius,
                  }}
                  size="large"
                  endIcon={<Iconify icon="ph:arrow-right" />}
                  variant="contained"
                  onClick={() => {
                    const newSelectedExercise = exercises[currentExerciseIndex + 1];
                    setSelectedExercise(newSelectedExercise);
                    setAnswersArray(
                      shuffleArray([
                        newSelectedExercise.correct_answer,
                        newSelectedExercise.wrong_answer_1,
                        newSelectedExercise.wrong_answer_2,
                        newSelectedExercise.wrong_answer_3,
                      ])
                    );
                    setSelectedExerciseIndex(currentExerciseIndex + 1);
                    setSelectedAnswer(null);
                  }}
                >
                  {`${translate(`ai.exercises.next`)}`}
                </Button>
              )}
            </Box>
          </DialogActions>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

type ResultsBoxProps = {
  amountCorrect: number;
  exercisesLength: number;
  onClose: (value: boolean) => void;
  handleGenerateExercises: (difficulty: string) => void;
};

const ResultsBox = ({
  amountCorrect,
  exercisesLength,
  onClose,
  handleGenerateExercises,
}: ResultsBoxProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const isPerfectScore = amountCorrect === exercisesLength;

  return (
    <Box
      sx={{
        borderRadius: theme.palette.borders.borderRadius,
        padding: '20px',
        width: '100%',
        backgroundColor: alpha(
          isPerfectScore ? theme.palette.secondary.main : theme.palette.pink.main,
          0.31
        ),
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <IconButton
        onClick={() => onClose(false)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: theme.palette.text.primary,
        }}
      >
        <Iconify icon="ph:x-circle" />
      </IconButton>

      <Typography variant="h5" gutterBottom>
        {String(translate('ai.exercises.youGot'))} {amountCorrect}/{exercisesLength}{' '}
        {String(translate('ai.exercises.correct'))}!
      </Typography>

      <Typography variant="body2" gutterBottom>
        {isPerfectScore
          ? String(translate('ai.exercises.correctText'))
          : String(translate('ai.exercises.wrongText'))}
      </Typography>

      <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
        <m.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          <Button
            color="info"
            variant="contained"
            size="medium"
            sx={{
              border: theme.palette.borders.border,
              boxShadow: theme.palette.borders.boxShadow,
              borderRadius: theme.palette.borders.borderRadius,
              '&:hover': {
                backgroundColor: theme.palette.pink.main,
                color: theme.palette.pink.contrastText,
                border: theme.palette.borders.border,
                boxShadow: theme.palette.borders.boxShadow,
                borderRadius: theme.palette.borders.borderRadius,
              },
            }}
            startIcon={<Iconify icon="ph:hammer" />}
            onClick={() => handleGenerateExercises(isPerfectScore ? 'harder' : 'same')}
          >
            {String(translate('ai.exercises.more'))}
          </Button>
        </m.div>
      </Stack>
    </Box>
  );
};
