import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Tooltip, Link, ListItemText, Chip, useTheme } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import Iconify from '../../iconify';
//
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const { pathname } = useLocation();

  const { title, path, icon, info, children, disabled, caption, roles, markets, freemium, isNew } =
    item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={`${translate(title)}`}
        secondary={
          caption && (
            <Tooltip title={`${translate(caption)}`} placement="top-start">
              <span>{`${translate(caption)}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {/* Show chip of new feature when sidebar is open */}
      {isNew && isDesktop && pathname !== '/ai' && pathname !== '/document' && (
        <Chip
          label={`${translate('overview.new')}!`}
          size="small"
          color="primary"
          sx={{
            ml: 1,
            height: 18,
            fontSize: 11,
            borderRadius: 1,
            minWidth: 'auto',
            // backgroundColor: 'primary.lighter',
            background: `linear-gradient(135deg, ${theme.palette.success.main} 0%, ${theme.palette.primary.main} 100%)`,
            color: 'primary.white',
            '& .MuiChip-label': {
              px: 1,
              lineHeight: '14px',
            },
          }}
        />
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return (
    <RoleBasedGuard freemium={freemium} roles={roles} markets={markets}>
      {renderItem()}
    </RoleBasedGuard>
  );
}
