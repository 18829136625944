// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  confirmation: path(ROOTS_AUTH, '/confirmation'),
  forgot: path(ROOTS_AUTH, '/forgot'),
  reset: path(ROOTS_AUTH, '/reset'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, 'dashboard'),
  calendar: path(ROOTS_DASHBOARD, 'calendar'),
  ai: {
    root: path(ROOTS_DASHBOARD, 'ai'),
  },
  notes: {
    root: path(ROOTS_DASHBOARD, 'document'),
  },
  family: {
    root: path(ROOTS_DASHBOARD, 'family'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `family/${id}`),
    teacherList: path(ROOTS_DASHBOARD, 'family/teacherList'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `family/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'family/new'),
    studentList: path(ROOTS_DASHBOARD, 'family/studentList'),
  },
  lessonRequest: {
    root: path(ROOTS_DASHBOARD, 'lesson-requests'),
    viewTeacherRequests: (id: string) => path(ROOTS_DASHBOARD, `lesson-requests/${id}`),
  },
  student: {
    root: path(ROOTS_DASHBOARD, 'student'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `student/${id}`),
    teacherList: path(ROOTS_DASHBOARD, 'student/teacherList'),
    new: path(ROOTS_DASHBOARD, 'student/new'),
  },
  tutor: {
    root: path(ROOTS_DASHBOARD, 'tutor'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `tutor/${id}`),
    studentList: path(ROOTS_DASHBOARD, `tutor/studentList`),
    earnings: (id: string) => path(ROOTS_DASHBOARD, `earnings/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `tutor/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'tutor/new'),
    course: path(ROOTS_DASHBOARD, 'tutor/courses'),
    viewCourse: (id: string) => path(ROOTS_DASHBOARD, `tutor/course/${id}`),
  },
  lesson: {
    root: path(ROOTS_DASHBOARD, 'lesson'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `lesson/${id}`),
    details: (id: string) => path(ROOTS_DASHBOARD, `lesson/${id}/details`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `lesson/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'lesson/new'),
  },
  report: {
    root: path(ROOTS_DASHBOARD, 'studentReport'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `student/${id}`),
  },
  email: {
    root: path(ROOTS_DASHBOARD, 'email'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `email/${id}`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, 'invoice'),
    customer: (customer_id: string) => path(ROOTS_DASHBOARD, `invoice/${customer_id}`),
    view: (customer_id: string, id: string) =>
      path(ROOTS_DASHBOARD, `invoice/${customer_id}/${id}`),
    edit: (customer_id: string, id: string) =>
      path(ROOTS_DASHBOARD, `invoice/${customer_id}/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'invoice/new'),
    custom: path(ROOTS_DASHBOARD, 'invoice/custom'),
  },
  payslip: {
    root: path(ROOTS_DASHBOARD, 'payslip'),
    profile: (tutor_id: string, id: string) => path(ROOTS_DASHBOARD, `payslip/${tutor_id}/${id}`),
  },
  balances: {
    root: path(ROOTS_DASHBOARD, 'balances'),
    customer: (customer_id: string) => path(ROOTS_DASHBOARD, `balances/${customer_id}`),
    new: path(ROOTS_DASHBOARD, 'balances/new'),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, 'transaction'),
    customer: (customer_id: string) => path(ROOTS_DASHBOARD, `transaction/${customer_id}`),
    profile: (customer_id: string, id: string) =>
      path(ROOTS_DASHBOARD, `transaction/${customer_id}/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `transaction/${id}/edit`),

    new: path(ROOTS_DASHBOARD, 'transaction/new'),
  },
  refer: {
    profile: (user_id: string) => path(ROOTS_DASHBOARD, `refer/${user_id}`),
  },
  education: {
    root: path(ROOTS_DASHBOARD, 'education'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `education/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'education/new'),
  },
  school: {
    root: path(ROOTS_DASHBOARD, 'school'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `school/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'school/new'),
  },
  subjects: {
    root: path(ROOTS_DASHBOARD, 'subjects'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `subjects/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'subjects/new'),
  },
  languages: {
    root: path(ROOTS_DASHBOARD, 'languages'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `languages/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'languages/new'),
  },
  interests: {
    root: path(ROOTS_DASHBOARD, 'interests'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `interests/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'interests/new'),
  },
  qualifications: {
    root: path(ROOTS_DASHBOARD, 'qualifications'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `qualifications/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'qualifications/new'),
  },
  programs: {
    root: path(ROOTS_DASHBOARD, 'programs'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `programs/${id}/edit`),
    new: path(ROOTS_DASHBOARD, 'programs/new'),
  },
  leaderboard: {
    root: path(ROOTS_DASHBOARD, 'leaderboard'),
  },
  membership: {
    root: path(ROOTS_DASHBOARD, 'membership'),
    order: (id: string) => path(ROOTS_DASHBOARD, `membership/${id}`),
    confirmation: (id: string) => path(ROOTS_DASHBOARD, `membership/confirmation/${id}`),
    view: (id: string) => path(ROOTS_DASHBOARD, `membership/view/${id}`),
  },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, 'chat'),
  //   new: path(ROOTS_DASHBOARD, 'chat/new'),
  //   view: (name: string) => path(ROOTS_DASHBOARD, `chat/${name}`),
  // },
};
