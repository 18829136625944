import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
//
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PRODUCTION_ENV } from './config-global';

// Sentry
import * as Sentry from '@sentry/react';
// Posthog
import posthog from 'posthog-js';

import { PostHogProvider } from 'posthog-js/react';

// ----------------------------------------------------------------------
// Init sentry on production
if (PRODUCTION_ENV != 'false') {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
    enable_recording_console_log: true,
    enable_heatmaps: true,
    persistence: 'cookie',
  });

  Sentry.init({
    dsn: 'https://b9ef7b1a075245268b4a2c015d91ebeb@o478493.ingest.sentry.io/4505176117673984',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      new posthog.SentryIntegration(posthog, 'toptutors', 4505176117673984, undefined, [
        'error',
        'info',
      ]),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions.
    tracePropagationTargets: [
      /^\//, // Match all relative URLs (same origin requests)
      /^https:\/\/www\.toptutors\.com\/api\//, // Match all requests to your API
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [/^ResizeObserver loop completed with undelivered notifications$/],
  });
}
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
function onUpdate(registration: ServiceWorkerRegistration) {
  // Notify the user about the update
  const updateNotification = window.confirm(
    'A new version is available. Would you like to update?'
  );

  if (updateNotification) {
    // Send a message to the service worker to skip waiting
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
}
// root.render(<App />);

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (PRODUCTION_ENV == 'false') {
  serviceWorkerRegistration.register({ onUpdate: onUpdate });
} else {
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
