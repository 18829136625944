import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import {
  updateFoldersAfterNoteCreation,
  updateFoldersAfterNoteUpdate,
  updateFoldersAfterNoteDeletion,
} from './folders';

import { INote, INoteState } from 'src/@types/note';

const initialState: INoteState = {
  isLoading: false,
  error: null,
  notes: [],
  note: null,
  improvements: [],
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // SET note to null
    setNoteNull(state) {
      state.note = null;
    },
    // STOPS LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // LOADS POTENTIAL IMPROVEMENTS
    generateImprovementsSuccess(state, action) {
      state.error = null;
      state.improvements = [...action.payload];
    },
    // POST Note to database
    postNoteSuccess(state, action) {
      state.isLoading = false;
      state.note = action.payload;
      state.error = null;
      state.notes = [action.payload, ...state.notes];
    },
    // GET All Notes
    getNotesSuccess(state, action) {
      state.isLoading = false;
      // Usually uses ...state.notes, ...action.payloard
      // Creates a weird double load, so removed ...state.notes for now
      state.notes = action.payload;
    },
    // GET Specific Note by ID
    getNoteSuccess(state, action) {
      state.isLoading = false;
      state.note = action.payload.data;
      state.error = null;
    },
    // PUT Updates onto note via ID
    getUpdateSuccess(state, action) {
      state.note = action.payload;
      state.notes = state.notes.map((note: INote) => {
        if (parseInt(note.id.toString() || '') === parseInt(action.payload.id)) {
          return action.payload;
        }
        return note;
      });
    },
    // DELETE Note via ID
    deleteNoteSuccess(state, action) {
      // Combine notes from both state and folder
      const allNotes = [...state.notes.filter((v) => v.id !== action.payload.old_note_id)];
      state.isLoading = false;
      state.error = null;
      state.notes = allNotes;
      state.note = action.payload.data;
    },
  },
});
console.log(slice);
export const { startLoading, stopLoading, deleteNoteSuccess } = slice.actions;
export default slice.reducer;
export function getJwtNoteToken() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/api/generate_jwt_token_tiptap`, {
        withCredentials: true,
      });
      console.log(response);
      if (response.status === 403) {
        throw new Error('Access denied');
      } else {
        dispatch(slice.actions.stopLoading());
        localStorage.setItem('aiTipTapToken', response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function startNoteEditor(user_id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.stopLoading());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/start_note_editor`,
        { user_id: user_id },
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Note not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getNoteSuccess(response));
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getNotes(user_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_notes/${user_id}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getNote(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_note/${id}`, {
        withCredentials: true,
      });
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getNoteSuccess(response));
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createNote(user_id: number, folderId?: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/create_note`, {
        user_id: user_id,
        folder_id: folderId,
        name: '',
        is_public: false,
        output_field: ``,
      });
      // console.log(response);
      if (response.data.folder_id) {
        dispatch(slice.actions.stopLoading());
        dispatch(updateFoldersAfterNoteCreation(response.data));
        dispatch(slice.actions.getNoteSuccess(response));
      } else {
        dispatch(slice.actions.postNoteSuccess(response.data));
      }
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function aiImproveContent(htmlContent: string, goalImprovement: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/improve_note`, {
        html_content: htmlContent,
        goal_improvement: goalImprovement,
      });
      console.log(response);
      dispatch(slice.actions.generateImprovementsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function saveNote(id: string | null, name: string, output_field: string) {
  return async (dispatch: Dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/update_note`, {
        id: id,
        name: name,
        output_field: output_field,
      });
      // console.log(response);
      if (response.data.folder_id) {
        dispatch(updateFoldersAfterNoteUpdate(response.data));
      } else {
        dispatch(slice.actions.getUpdateSuccess(response.data));
      }
      dispatch(slice.actions.stopLoading());

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function publicizeNote(id: string | null) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/publicize_note`, {
        id: id,
        is_public: true,
      });
      // console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function deleteNote(note: INote) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/delete_note/${note.id}`, {});
      // console.log(response);
      if (note.folder_id) {
        dispatch(updateFoldersAfterNoteDeletion(note.id));
      }
      dispatch(slice.actions.deleteNoteSuccess({ data: response.data, old_note_id: note.id }));

      dispatch(slice.actions.stopLoading());
      return response.data;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function exportNote(htmlContent: string, fileType: string, fileName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const contentType =
        fileType === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

      const response = await axios.post(
        `/api/export_note`,
        {
          htmlContent: htmlContent,
          fileType: fileType,
        },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            withCredentials: true,
            Accept: contentType,
          },
        }
      );

      const blob = new Blob([response.data], { type: contentType });
      const fileExtension = fileType === 'pdf' ? '.pdf' : '.docx';
      const safeFileName = fileName.trim().replace(/\.$/, '') || 'toptutors_export';
      const fullFileName = `${safeFileName}${fileExtension}`;

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fullFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(slice.actions.stopLoading());
      return true;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.message || 'An error occurred during export';
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createAINote(
  user_id: number | null,
  subject: string,
  classLevel: string,
  topic: string,
  files: File[], // Changed to string[]
  style: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setNoteNull());
      const formData = new FormData();
      if (user_id) {
        formData.append('user_id', user_id.toString());
      }
      formData.append('subject', subject);
      formData.append('classLevel', classLevel);
      formData.append('topic', topic);

      for (const file of files) {
        formData.append('file', file);
      }

      formData.append('style', style);

      const response = await axios.post('/api/create_ai_note', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch(slice.actions.postNoteSuccess(response.data));
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createAIEssay(
  user_id: number | null,
  subject: string,
  classLevel: string,
  topic: string,
  details: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const formData = new FormData();
      if (user_id) {
        formData.append('user_id', user_id.toString());
      }
      formData.append('subject', subject);
      formData.append('classLevel', classLevel);
      formData.append('topic', topic);
      formData.append('details', details);

      const response = await axios.post('/api/create_ai_essay', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch(slice.actions.postNoteSuccess(response.data));
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createDocument(note_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/create_document`, {
        note_id: note_id,
        // needs to somehow generate token
        storage_string: 'temp string',
      });
      console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
