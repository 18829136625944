// @mui
import { Badge, IconButton, Tooltip } from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';
import { useLocales } from '../../../locales';

type Props = {
  monthly_credits_left: number;
};

export default function PurchaseMembershipWidget({ monthly_credits_left }: Props) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { translate } = useLocales();

  // If user is not a customer then don't show this
  if (!user?.customer_dict) {
    return null;
  }

  //  Check if user.customer_dict and its properties exist
  const hasMembership = user.customer_dict.has_membership;
  const customerId = user.customer_dict.id;

  // Check the condition and return null if it doesn't meet our criteria
  if (monthly_credits_left !== 0 || hasMembership) {
    return null;
  }

  // user?.customer_dict?.has_membership && user.customer_dict.monthly_credits_ordered !== 0

  return (
    <Tooltip title={String(translate('customer.membership.widgetTooltip'))}>
      <IconButton
        onClick={() => {
          navigate(`/membership/order/${customerId}`);
        }}
        sx={{ mr: 1, color: 'text.primary' }}
      >
        <Badge
          badgeContent={1}
          sx={{
            '& .MuiBadge-badge': {
              right: -3,
              top: 10,
              border: `2px solid `,
              padding: '0 4px',
            },
          }}
          color="error"
        >
          <Iconify icon="ph:shopping-cart" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
