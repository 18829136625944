import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IAIState, IExercises, IAIhistory } from '../../@types/ai';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { dispatch as dispatchFunction } from '../store';

const initialState: IAIState = {
  isLoading: false,
  isLoadingExercises: false,
  isLoadingReview: false,
  openChat: false,
  error: null,
  ai_chat_id: null,
  current_response: '',
  ai_responses: [],
  audioUrl: null,
  allowReview: false,
  exercises: [],
  other_principals: [],
  follow_up_questions: [],
  chatHistory: [],
  isLoadingChatHistory: false,
  isLoadingChatMessages: false,
  promptRunning: false,
};

const slice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    startReview(state) {
      state.isLoadingReview = true;
      state.allowReview = false;
    },
    stateClearPropmtSignup(state) {
      state.isLoading = false;
      state.isLoadingExercises = false;
      state.openChat = true;
      state.error = null;
      // Temp message until translate gets reworked to work with .ts files
      // state.ai_responses[state.ai_responses.length - 1].responses.push(
      //   'I can see you have recently signed up, can you please repeat your question?'
      // );
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    receivedAudioUrl(state, action) {
      state.audioUrl = action.payload;
    },
    startedHelp(state, action) {
      state.isLoading = false;
      state.error = null;
      state.openChat = true;
      state.ai_responses = [
        ...state.ai_responses,
        {
          prompt: action.payload.prompt,
          responses: action.payload.response,
          isPaused: false,
          isFile: action.payload.file,
          fileLink: action.payload.file ? action.payload.fileLink : '',
        },
      ];
      state.exercises = action.payload.exercises;
      state.other_principals = action.payload.other_principals;
      state.follow_up_questions = action.payload.follow_up_questions;
      state.ai_chat_id = action.payload.ai_chat_id;
      state.allowReview = action.payload.allow_review;
    },
    hardcodedSelection(state, action) {
      state.ai_responses = [...action.payload];
    },
    hardcodedSelectionFollowUps(state, action) {
      state.follow_up_questions = [...action.payload];
    },
    hardcodedSelectionExercises(state, action) {
      state.exercises = [...action.payload];
    },
    openedDataStream(state, action) {
      state.isLoading = true;
      state.openChat = true;
      state.error = null;
      state.ai_responses = [
        ...state.ai_responses,
        {
          prompt: action.payload.prompt,
          responses: [''],
          isPaused: false,
          isFile: action.payload.isFile,
          fileLink: action.payload.fileLink,
        },
      ];
      state.current_response = '';
    },
    receivedNewLineData(state, action) {
      state.isLoading = false;
      state.error = null;
      state.current_response = '';
      state.ai_responses[state.ai_responses.length - 1].responses.push('');
    },
    removeLineData(state, action) {
      state.isLoading = false;
      state.error = null;
      state.current_response = '';
      state.ai_responses[state.ai_responses.length - 1].responses.pop();
    },
    receivedStepData(state, action) {
      state.isLoading = false;
      state.error = null;
      state.current_response = '';
      state.ai_responses[state.ai_responses.length - 1].isPaused = true;
    },
    receivedStreamData(state, action) {
      state.isLoading = false;
      state.error = null;
      state.current_response = action.payload.response;
      state.ai_responses[state.ai_responses.length - 1].responses[
        state.ai_responses[state.ai_responses.length - 1].responses.length - 1
      ] = action.payload.response;
    },
    generatedNewFollowUps(state, action) {
      state.isLoadingExercises = false;
      state.error = null;
      state.follow_up_questions = action.payload.follow_up;
    },
    setChatId(state, action) {
      state.ai_chat_id = action.payload;
    },
    setPromptRunning(state, action) {
      state.promptRunning = action.payload;
    },
    loadExercises(state, action) {
      state.isLoadingExercises = true;
    },
    generatedOnlyExercises(state, action) {
      state.isLoadingExercises = false;
      state.error = null;
      state.exercises = action.payload.exercises;
    },
    completedReview(state, action) {
      state.error = null;
      state.isLoadingReview = false;
      state.allowReview = false;
    },
    // Action to start loading chat history
    startLoadingChatHistory(state) {
      state.isLoadingChatHistory = true;
    },
    //Action to update the chat history
    updateChatHistoryState(state, action) {
      // make sure id is not within the history and then append it
      if (!state.chatHistory.find((chat: IAIhistory) => chat.id === action.payload.id)) {
        state.isLoadingChatHistory = false;
        state.chatHistory = [...state.chatHistory, action.payload];
      }
    },

    setChatHistory(state, action) {
      state.isLoadingChatHistory = false;
      state.chatHistory = [...action.payload];
    },
    // Action to start loading chat messages
    startLoadingChatMessages(state) {
      state.isLoadingChatMessages = true;
    },
    // Action to set chat messages for a specific chat
    setChatMessages(state, action) {
      state.isLoadingChatMessages = false;
      state.isLoading = false;
      state.openChat = true;
      const { chatId, messages } = action.payload;
      state.ai_responses = messages;
      state.ai_chat_id = chatId;
    },
  },
});
export const {
  hardcodedSelection,
  hardcodedSelectionFollowUps,
  hardcodedSelectionExercises,
  startLoadingChatHistory,
  setChatHistory,
  startLoadingChatMessages,
  setChatMessages,
} = slice.actions;
export default slice.reducer;
export function clearPropmtSignip() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.stateClearPropmtSignup());
  };
}
// Thunk to fetch AI chat messages for a specific chat session
export function fetchAIChatHistory(chatId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoadingChatMessages());
    try {
      const response = await axios.get(`/api/ai_chat_messages/${chatId}`);
      dispatch(slice.actions.setChatMessages({ chatId, messages: response.data }));
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else if (typeof error == 'string' && error.includes('Too Many Requests')) {
        errorMessage = 'Limit reached';
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

// Thunk to fetch AI chat history for the authenticated user
export function getAiChatHistoryList(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoadingChatHistory());
    try {
      const response = await axios.get(`/api/ai_chat/${userId}`);
      dispatch(slice.actions.setChatHistory(response.data));
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else if (typeof error == 'string' && error.includes('Too Many Requests')) {
        errorMessage = 'Limit reached';
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function generateFollowUps(
  exercises: IExercises[],
  language: string,
  freemium: boolean | false,
  prompt?: string,
  only_exercises?: boolean,
  difficulty?: string,
  subject?: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.loadExercises(''));
    const url = freemium ? 'api/generate_new_follow_ups_free' : 'api/generate_new_follow_ups';
    try {
      const just_exercises = exercises.map((exercise: IExercises) => exercise.exercise);
      const response = await axios.post(
        url,
        {
          prompt: just_exercises,
          original_prompt: prompt,
          difficulty: difficulty,
          language: language,
          subject: subject,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 404) {
        throw new Error('Student streaks not found');
      } else if (response.status === 429) {
        throw Error('Limit reached');
      } else {
        if (response.data.error) {
          throw new Error('Non-mathematical prompts not allowed here');
        }

        dispatch(
          slice.actions.generatedNewFollowUps({
            other_principals: response.data.other_principals,
            follow_up: response.data.followUps,
          })
        );

        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else if (typeof error == 'string' && error.includes('Too Many Requests')) {
        errorMessage = 'Limit reached';
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function generateExercises(
  exercises: IExercises[],
  prompt: string,
  language: string,
  freemium: boolean | false,
  subject: string,
  difficulty?: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.loadExercises(''));
    const url = freemium ? 'api/generate_new_exercises_free' : 'api/generate_new_exercises';
    try {
      const just_exercises = exercises.map((exercise: IExercises) => exercise.exercise);
      const response = await axios.post(
        url,
        {
          prompt: just_exercises,
          original_prompt: prompt,
          difficulty: difficulty,
          language: language,
          subject: subject,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 404) {
        throw new Error('Student streaks not found');
      } else if (response.status === 429) {
        throw Error('Limit reached');
      } else {
        if (response.data.error) {
          throw new Error('Non-mathematical prompts not allowed here');
        }
        dispatch(
          slice.actions.generatedOnlyExercises({
            exercises: response.data.exercises,
          })
        );

        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else if (typeof error == 'string' && error.includes('Too Many Requests')) {
        errorMessage = 'Limit reached';
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function uploadScreenshot(
  base64String: string,
  uid: number | null,
  chat_id: number | null,
  language: string,
  grade: string,
  level: string,
  subject: string,
  cookieUses?: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const url = cookieUses ? 'api/screenshot_free' : '/api/screenshot';
      const response = await axios.post(
        url,
        {
          url: base64String,
          ...(uid !== null ? { user_id: uid } : {}),
          language: language,
          grade: grade,
          level: level,
          subject: subject,
          ...(chat_id !== null ? { chat_id: chat_id } : {}),
        },

        { headers: { Aiuses: `${cookieUses}` }, withCredentials: true }
      );
      if (response.status === 404) {
        throw new Error('Student streaks not found');
      } else if (response.status === 429) {
        throw Error('Limit reached');
      } else {
        if (response.data.error) {
          throw new Error('Non-mathematical prompts not allowed here');
        }
        if (uid) {
          dispatchFunction(
            generateFollowUps(
              [],
              language,
              false,
              response.data.analysis_image,
              undefined,
              '',
              subject
            )
          );
          await dispatchFunction(
            getAiHelp(
              response.data.analysis_image,
              language,
              uid,
              response.data.ai_chat,
              grade,
              level,
              true,
              cookieUses ? base64String : response.data.file_link,
              subject
            )
          );
        } else {
          dispatchFunction(
            generateFollowUps(
              [],
              language,
              true,
              response.data.analysis_image,
              undefined,
              '',
              subject
            )
          );
          // console.log(base64String);
          await dispatchFunction(
            getAiHelpFree(
              response.data.analysis_image,
              language,
              grade,
              level,
              cookieUses,
              response.data.ai_chat,
              true,
              cookieUses ? base64String : response.data.file_link,
              subject
            )
          );
        }
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function initializeCookie() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/api/initiate', {
        withCredentials: true,
      });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function uploadFile(
  formData: FormData,
  uid: number | null,
  chat_id: number | null,
  language: string,
  grade: string,
  level: string,
  subject: string,
  cookieUses?: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // Append uid to formData
    if (uid) {
      formData.append('user_id', uid.toString());
    }
    formData.append('level', level);
    formData.append('grade', grade);
    formData.append('language', language);
    formData.append('subject', subject);
    // Conditionally append chat_id to formData if it's not null
    if (chat_id !== null) {
      formData.append('chat_id', chat_id.toString());
    }
    try {
      const url = cookieUses ? 'api/analyze_file_free' : '/api/analyze_file';
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Student streaks not found');
      } else if (response.status === 429) {
        throw Error('Limit reached');
      } else {
        if (response.data.error) {
          throw new Error('Non-mathematical prompts not allowed here');
        }
        if (uid) {
          await dispatchFunction(
            generateFollowUps([], language, false, response.data.analysis_image, undefined, '')
          );
          console.log(response.data.file_link);
          await dispatchFunction(
            getAiHelp(
              response.data.analysis_image,
              language,
              uid,
              response.data.ai_chat,
              grade,
              level,
              true,
              cookieUses ? formData.get('preview') : response.data.file_link,
              subject || ''
            )
          );
        } else {
          await dispatchFunction(
            generateFollowUps([], language, true, response.data.analysis_image, undefined, '')
          );
          await dispatchFunction(
            getAiHelpFree(
              response.data.analysis_image,
              language,
              grade,
              level,
              cookieUses,
              response.data.ai_chat,
              true,
              cookieUses ? formData.get('preview') : response.data.file_link,

              subject || ''
            )
          );
        }
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function reviewPrompt(
  message_id: string,
  user_id: number,
  opinion: string,
  review_value: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startReview());
    try {
      const response = await axios.post(
        'api/review',
        {
          message_id: message_id,
          opinion: opinion,
          review_value: review_value,
          user_id: user_id,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Message not found');
      } else {
        if (response.data.error) {
          throw new Error('Error generating data');
        }
      }
      dispatch(slice.actions.completedReview(''));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getAiHelp(
  prompt: string,
  language: string,
  uid: number,
  chat_id: number | null,
  grade: string,
  level: string,
  isFile: boolean,
  fileLink: string,
  subject: string
) {
  // This function returns a thunk, which is a function that can be dispatched to the Redux store
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(slice.actions.startLoading());
      console.log(prompt);
      dispatch(
        slice.actions.openedDataStream({ prompt: prompt, isFile: isFile, fileLink: fileLink })
      );

      // Set up the EventSource for server-sent events
      const url = process.env.REACT_APP_HOST_API_KEY || '';
      const eventSource = new EventSourcePolyfill(
        `${url}/api/ai_help?prompt=${encodeURIComponent(
          prompt
        )}&language=${language}&user_id=${uid}&chat_id=${chat_id}&grade=${grade}&level=${level}&subject=${subject}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      let messageString = '';

      // Timeout handling
      const timeoutHandler = () => {
        console.log('EventSource request timed out');
        eventSource.close();
        dispatch(
          slice.actions.receivedStreamData({
            response:
              messageString +
              'An unexpected error occurred! Can you please try again asking me the question?',
          })
        );
        dispatch(slice.actions.hasError('Request timed out'));
        reject(false);
      };

      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      const timeoutDuration = 100000; // 100 seconds

      const clearEventSourceTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
      };

      dispatch(slice.actions.setPromptRunning(true));

      // Handle incoming messages from the server
      eventSource.onmessage = async (event) => {
        clearEventSourceTimeout(); // Reset timeout on each message

        if (event.data === 'ENDSTREAM') {
          eventSource.close();
          try {
            dispatch(slice.actions.setPromptRunning(false));
            resolve(true);
          } catch (error) {
            console.log(error);
            console.error('Error parsing data:', error);
            reject(false);
          }
        } else {
          if (event.data === 'PAUSE') {
            messageString = '';
            dispatch(slice.actions.receivedNewLineData(''));
          } else if (event.data.includes('CHATID_')) {
            // Handle new chat ID
            const newChatId = parseInt(event.data.split('CHATID_')[1], 10);
            dispatch(slice.actions.setChatId(newChatId));
            // Update chat history if the chat ID is new
            const newChat: IAIhistory = {
              id: newChatId,
              title: prompt,
              user_id: uid,
              messages_list: [],
              created_at: new Date().toISOString(),
              last_updated: new Date().toISOString(),
            };
            dispatch(slice.actions.updateChatHistoryState(newChat));
          } else {
            // Handle regular message data
            dispatch(
              slice.actions.receivedStreamData({
                response: messageString + event.data,
              })
            );
            messageString += event.data;
          }
          // Reset the timeout for the next message
          timeoutId = setTimeout(timeoutHandler, timeoutDuration);
        }
      };

      // Handle errors from the EventSource
      eventSource.onerror = function (error) {
        console.log('EventSource encountered an error:', error);
        console.log('Error', error.type);
        console.log('Error', error.target);
        clearEventSourceTimeout();
        eventSource.close();
        dispatch(
          slice.actions.receivedStreamData({
            response:
              messageString +
              'An unexpected error occurred! Can you please try again asking me the question?',
          })
        );
        dispatch(slice.actions.hasError('Error with message'));
        reject(false);
      };

      // Start the initial timeout
      timeoutId = setTimeout(timeoutHandler, timeoutDuration);
    });
  };
}

export function getAiHelpFree(
  prompt: string,
  language: string,
  grade: string,
  level: string,
  cookies: number | undefined,
  chat_id: number | null,
  isFile: boolean,
  fileLink: string,
  subject: string
) {
  return (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(slice.actions.startLoading());
      dispatch(
        slice.actions.openedDataStream({ prompt: prompt, isFile: isFile, fileLink: fileLink })
      );

      const url = process.env.REACT_APP_HOST_API_KEY || '';
      const eventSource = new EventSourcePolyfill(
        `${url}/api/ai_help_free?prompt=${encodeURIComponent(
          prompt
        )}&language=${language}&grade=${grade}&level=${level}&chat_id=${chat_id}&subject=${subject}`,
        {
          withCredentials: true,
          headers: {
            Aiuses: `${cookies}`,
          },
        }
      );
      let messageString = '';
      const timeoutHandler = () => {
        console.log('EventSource request timed out');
        eventSource.close();
        dispatch(
          slice.actions.receivedStreamData({
            response:
              messageString +
              'An unexpected error occurred! Can you please try again asking me the question?',
          })
        );
        dispatch(slice.actions.hasError('Request timed out'));
        reject(false);
      };
      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      const timeoutDuration = 100000; // Set the timeout duration (in milliseconds)
      const clearEventSourceTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
      };
      eventSource.onmessage = function (event) {
        clearEventSourceTimeout(); // Clear the timeout as we've received a message

        if (event.data === 'ENDSTREAM') {
          eventSource.close();
          try {
            resolve(true);
          } catch (error) {
            console.log(error);
            console.error('Error parsing data:', error);

            reject(false);
          }
        } else if (event.data === 'PAUSE') {
          messageString = '';
          dispatch(slice.actions.receivedNewLineData(''));
          // dispatch(slice.actions.removeLineData(''));
          // } else if (event.data === 'NEWSTEP') {
        } else if (event.data.includes('CHATID_')) {
          dispatch(slice.actions.setChatId(event.data.split('CHATID_')[1]));
        } else {
          dispatch(
            slice.actions.receivedStreamData({
              response: messageString + event.data,
            })
          );
          messageString += event.data;
        }
      };
      eventSource.onerror = function (error) {
        console.log('EventSource encountered an error:', error);
        console.log('EventSource encountered an error:', error);
        console.log('Error', error.type);
        console.log('Error', error.target);
        const status = (error as any).status || (error.target as XMLHttpRequest)?.status;
        console.log('Error status:', status);
        if (status === 429) {
          dispatch(slice.actions.hasError('Limit reached'));
          dispatch(
            slice.actions.receivedStreamData({
              response: messageString + 'Limit reached! Please create an account to continue?',
            })
          );
        } else {
          dispatch(slice.actions.hasError('Error with message'));
          dispatch(
            slice.actions.receivedStreamData({
              response:
                messageString +
                'An unexpected error occurred! Can you please try again asking me the question?',
            })
          );
        }
        eventSource.close();
        clearEventSourceTimeout();
        eventSource.close();

        dispatch(slice.actions.hasError('Error with message'));
        reject(false);
      };
      timeoutId = setTimeout(timeoutHandler, timeoutDuration);
    });
  };
}
