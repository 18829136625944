import { useState } from 'react';
// @mui
import { MenuItem, Stack, Tooltip } from '@mui/material';
import Image from '../../components/image';
import MenuPopover from '../../components/menu-popover';
import { IconButtonAnimate } from '../../components/animate';
import { useDispatch } from 'src/redux/store';

type Props = {
  width?: number;
  height?: number;
  user_id: number;
  updateMarket: (id: number, newMarket: string) => void;
};

export default function SelectMarket({ user_id, updateMarket }: Props) {
  const allMarkets = [
    {
      label: 'Dansk',
      value: 1,
      icon: '/assets/icons/flags/ic_flag_da.svg',
    },
    {
      label: 'Spain',
      value: 2,
      icon: '/assets/icons/flags/ic_flag_es.svg',
    },
  ];

  const defaultMarket = allMarkets.find(
    (m) => m.value.toString() === localStorage.getItem('market_id')
  );
  const [currentMarket, setCurrentMarket] = useState(defaultMarket || allMarkets[0]);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const dispatch = useDispatch();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: number) => {
    const searched = allMarkets.filter((market) => market.value === newLang);
    setCurrentMarket(searched[0]);
    sessionStorage.setItem('market_id', newLang.toString());
    localStorage.setItem('market_id', newLang.toString());
    updateMarket(user_id, newLang.toString());
    handleClosePopover();
  };

  return (
    <>
      <Tooltip title="Select Market">
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            width: 40,
            height: 40,
            ...(openPopover && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <Image
            title="Select Market"
            disabledEffect
            src={currentMarket.icon}
            alt={currentMarket.label}
          />
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
        <Stack spacing={0.4}>
          {allMarkets.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentMarket.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
