import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    groundingGrey: {
      main: string;
      lighter: string;
      darker: string;
      contrastText: string;
      highlightText: string;
    };
    pink: Palette['primary'];
    borders: {
      borderRadius: string;
      border: string;
      boxShadow: string;
      boxShadowHoverBlack: string;
      boxShadowLarge: string;
      boxShadowHoverPrimary: string;
      boxShadowHoverSecondary: string;
      boxShadowHoverSuccess: string;
      boxShadowHoverError: string;
      boxShadowError: string;
    };
  }
  interface PaletteOptions {
    groundingGrey: {
      main: string;
      lighter: string;
      darker: string;
      contrastText: string;
      highlightText: string;
    };
    borders: {
      borderRadius: string;
      border: string;
      boxShadow: string;
      boxShadowHoverBlack: string;
      boxShadowLarge: string;
      boxShadowHoverPrimary: string;
      boxShadowHoverSecondary: string;
      boxShadowHoverSuccess: string;
      boxShadowHoverError: string;
      boxShadowError: string;
    };
  }
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
    contrastText?: string;
    highlightText?: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
    contrastText: string;
    highlightText?: string;
  }
}

// SETUP COLORS

// PRIMARY COLORS & BACKGROUND COLORS
const CALMING_GREEN = {
  main: '#007850',
  lighter: '#007850',
  darker: '#007850',
  contrastText: '#FAFAFA',
  highlightText: '#CFFFD2',
};

const MINTY_MIND = {
  main: '#CFFFD2', // D6FFCC is the original color
  lighter: '#EBFFEC',
  darker: '#C2FFC5',
  contrastText: '#007850',
  highlightText: '#F75B2A',
};

const GROUNDING_GREY = {
  main: '#EDE7E2',
  lighter: '#F6F3F0',
  darker: '#EDE7E2',
  contrastText: '#333C4B',
  highlightText: '#F75B2A',
};

const PAPER_WHITE = {
  main: '#FAFAFA',
  lighter: '#FAFAFA',
  darker: '#FAFAFA',
  contrastText: '#333C4B',
  highlightText: '#007850',
};

// OTHER PRIMARY COLORS
const NIGHT_FALL = {
  main: '#333C4B',
  lighter: '#333C4B',
  darker: '#333C4B',
  contrastText: '#FAFAFA',
};

const AWESOME_ORANGE = {
  main: '#F75B2A',
  lighter: '#FEF0EB',
  darker: '#EC3E09',
  darkest: '#B12306',
  contrastText: '#FAFAFA',
  highlightText: '#CFFFD2',
};

// ACCENT COLORS
const PREPPY_PINK = {
  main: '#FFCADD',
  lighter: '#FFEBF2',
  darker: '#FFCADD',
  contrastText: '#333C4B',
};

const SUNSHINE_YELLOW = {
  main: '#FFD400',
  lighter: '#FFFCEB',
  darker: '#FFD400',
  contrastText: '#333C4B',
};

const LIGHT_SOOTHING_BLUE = {
  main: '#9FE4FF',
  lighter: '#EBF9FF',
  darker: '#9FE4FF',
  contrastText: '#333C4B',
};

const TOPPER_THE_TURTLE_GREEN = {
  main: '#00B574',
  lighter: '#EBFFF8',
  darker: '#00B574',
  contrastText: '#FAFAFA',
};

const GREY = {
  100: GROUNDING_GREY.main,
  200: GROUNDING_GREY.main,
  300: GROUNDING_GREY.main,
  400: GROUNDING_GREY.main,
  500: GROUNDING_GREY.main,
  600: GROUNDING_GREY.main,
  700: GROUNDING_GREY.main,
  800: GROUNDING_GREY.main,
  900: GROUNDING_GREY.main,
};

const COMMON = {
  common: { black: NIGHT_FALL.main, white: PAPER_WHITE.main },
  primary: CALMING_GREEN,
  secondary: MINTY_MIND,
  info: LIGHT_SOOTHING_BLUE,
  success: TOPPER_THE_TURTLE_GREEN,
  warning: SUNSHINE_YELLOW,
  error: AWESOME_ORANGE,
  grey: GREY,
  pink: PREPPY_PINK,
  black: NIGHT_FALL,
  white: PAPER_WHITE,
  groundingGrey: GROUNDING_GREY,
  divider: GROUNDING_GREY.main,
  action: {
    hover: alpha(CALMING_GREEN.main, 0.1),
    selected: alpha(CALMING_GREEN.main, 0.12),
    disabled: alpha(NIGHT_FALL.main, 0.25),
    disabledBackground: GROUNDING_GREY.main,
    focus: alpha(NIGHT_FALL.main, 0.2),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette() {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: NIGHT_FALL.main,
      secondary: NIGHT_FALL.main,
      disabled: alpha(NIGHT_FALL.main, 0.6),
    },
    background: {
      paper: PAPER_WHITE.main,
      default: PAPER_WHITE.main,
      neutral: GROUNDING_GREY.main,
    },
    action: {
      ...COMMON.action,
      active: CALMING_GREEN.main,
    },
    borders: {
      borderRadius: '25px',
      border: `1px solid ${NIGHT_FALL.main}`,
      boxShadow: `0px 2px 0px ${NIGHT_FALL.main}`,
      boxShadowHoverBlack: `0px 2px 0px ${NIGHT_FALL.main}`,
      boxShadowLarge: `0px 6px 0px ${NIGHT_FALL.main}`,
      boxShadowHoverPrimary: `0px 2px 0px ${CALMING_GREEN.main}`,
      boxShadowHoverSecondary: `0px 2px 0px ${MINTY_MIND.main}`,
      boxShadowHoverSuccess: `0px 2px 0px ${TOPPER_THE_TURTLE_GREEN.main}`,
      boxShadowError: `0px 3px 0px ${AWESOME_ORANGE.darker}`,
      boxShadowHoverError: `0px 6px 0px ${AWESOME_ORANGE.darkest}`,
    },
  } as const;

  return light;
}
