import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IFolderState, IFolder } from 'src/@types/folders';
import { INote } from 'src/@types/note';
const initialState: IFolderState = {
  isLoading: false,
  error: null,
  folders: [],
  folder: null,
};

const slice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postFolderSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.folders = [action.payload, ...state.folders];
    },
    getFoldersSuccess(state, action) {
      state.isLoading = false;
      state.folders = [...action.payload];
    },
    getFolderSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.folder = action.payload;
    },
    updateFolderSuccess(state, action) {
      state.folders = state.folders.map((folder: IFolder) =>
        folder.id === action.payload.id ? action.payload : folder
      );
    },
    deleteFolderSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.folder = null;
      state.folders = state.folders.filter((folder) => folder.id !== action.payload.id);
    },
    updateFoldersAfterNoteCreation(state, action) {
      state.folders = state.folders.map((folder) => {
        if (folder.id === parseInt(action.payload.folder_id)) {
          folder.notes.unshift(action.payload);
        }
        return folder;
      });
    },
    updateFoldersAfterNoteUpdate(state, action) {
      state.folders = state.folders.map((folder) => {
        if (folder.id === parseInt(action.payload.folder_id)) {
          const updatedFolderNotes = folder.notes.map((note: INote) => {
            if (parseInt(note.id.toString() || '') === parseInt(action.payload.id)) {
              return action.payload;
            }
            return note;
          });
          return { ...folder, notes: updatedFolderNotes };
        }

        return folder;
      });
    },
    updateFoldersAfterDocumentCreation(state, action) {
      state.folders = state.folders.map((folder) => {
        if (folder.id === parseInt(action.payload.folder_id)) {
          folder.documents.unshift(action.payload);
        }
        return folder;
      });
    },
    updateFoldersAfterNoteDeletion(state, action) {
      const deletedNoteId = action.payload;
      state.folders = state.folders.map((folder) => ({
        ...folder,
        notes: folder.notes.filter((note) => note.id !== deletedNoteId),
      }));
    },
  },
});
export const {
  updateFoldersAfterNoteCreation,
  updateFoldersAfterDocumentCreation,
  updateFoldersAfterNoteUpdate,
  updateFoldersAfterNoteDeletion,
} = slice.actions;
export default slice.reducer;

// Fetch all folders for a user
export function getFolders(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/folders/user/${userId}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getFoldersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(getErrorMessage(error)));
      return false;
    }
  };
}

// Fetch a specific folder by ID
export function getFolder(folderId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/folders/${folderId}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getFolderSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(getErrorMessage(error)));
      return false;
    }
  };
}

// Edit an existing folder
export function editFolder(folderId: number, folderData: Partial<IFolder>) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/folders/${folderId}`, folderData, {
        withCredentials: true,
      });
      dispatch(slice.actions.updateFolderSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(getErrorMessage(error)));
      return false;
    }
  };
}

// Create a new folder
export function createFolder(folderName: string, userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/folders`,
        { name: folderName, user_id: userId },
        { withCredentials: true }
      );
      dispatch(slice.actions.updateFolderSuccess(response.data));
      return { success: true, id: response.data.id };
    } catch (error) {
      dispatch(slice.actions.hasError(getErrorMessage(error)));
      return false;
    }
  };
}

// Delete a folder
export function deleteFolder(folder: IFolder) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/folders/${folder.id}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.deleteFolderSuccess(response.data));
      // Dispatch a delte here to set the note to the latest after deleting
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(getErrorMessage(error)));
      return false;
    }
  };
}

// Helper function to extract error messages
function getErrorMessage(error: any): string {
  if (error?.errors?.json?._schema) {
    return error.errors.json._schema[0];
  } else if (error?.errors?.json) {
    return error.errors.json[Object.keys(error.errors.json)[0]];
  } else if (error?.response?.data?.errors?.json?._schema) {
    return error.response.data.errors.json._schema[0];
  } else if (error?.response?.data?.errors?.json) {
    const firstErrorKey = Object.keys(error.response.data.errors.json)[0];
    return error.response.data.errors.json[firstErrorKey];
  } else {
    return error?.message || 'An unexpected error occurred';
  }
}
