import styled from 'styled-components';

import { m, Transition } from 'framer-motion';
import { useTheme } from '@mui/material';

export function LoadingDots() {
  const theme = useTheme();

  const dotTransition: Transition = {
    repeat: Infinity,
    repeatType: 'mirror',
    duration: 0.9,
  };

  const Dot = styled(m.div)`
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  `;

  return (
    <div style={{ display: 'flex' }}>
      <Dot
        className="dot"
        animate={{
          background: [
            'radial-gradient(70% 0% at 50% 110%, #00B574 0%, rgb(0, 120, 80) 100%)',
            'radial-gradient(70% 100% at 50% 100%, #00B574 0%, rgb(0, 120, 80) 100%)',
          ],
          scale: [0.5, 1],
        }}
        transition={{
          ...dotTransition,
          delay: -0.9,
        }}
      />
      <Dot
        className="dot"
        animate={{
          background: [
            'radial-gradient(70% 0% at 50% 110%, #00B574 0%, rgb(0, 120, 80) 100%)',
            'radial-gradient(70% 100% at 50% 100%, #00B574 0%, rgb(0, 120, 80) 100%)',
          ],
          scale: [0.5, 1],
        }}
        transition={{
          ...dotTransition,
          delay: -0.6,
        }}
      />
      <Dot
        className="dot"
        animate={{
          background: [
            'radial-gradient(70% 0% at 50% 110%, #00B574 0%, rgb(0, 120, 80) 100%)',
            'radial-gradient(70% 100% at 50% 100%, #00B574 0%, rgb(0, 120, 80) 100%)',
          ],
          scale: [0.5, 1],
        }}
        transition={{
          ...dotTransition,
          delay: -0.3,
        }}
      />
    </div>
  );
}

LoadingDots.displayName = 'LoadingDots';

export default LoadingDots;
