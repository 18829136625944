import { Box, Grid, Typography, Button, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import Latex from 'react-latex-next';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import { useLocales } from 'src/locales';
import useResponsive from 'src/hooks/useResponsive';
type Subject = {
  label: string;
  icon: string;
  value: string;
  disabled: boolean;
  translateLabel: string;
  translateDisabled: string;
};
type Props = {
  subject: Subject;
  handleQuestionEmpty?: (id: number, question: string) => void;
  handleQuestion: (question: string) => void;
  setOpen: (open: boolean) => void;
};

export default function HelperAiText({
  subject,
  handleQuestion,
  handleQuestionEmpty,
  setOpen,
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'sm');
  const subject_name = subject.value;
  const sections = [
    {
      key: 'understand',
      showMobile: false,
      buttons: [
        { id: 1, label: `ai.helperText.${subject_name}.pythagoras` },
        { id: 3, label: `ai.helperText.${subject_name}.minusser` },
      ],
    },
    {
      key: 'solve',
      showMobile: true,
      buttons: [
        { id: 5, label: `ai.helperText.${subject_name}.picture` },
        { id: 4, label: `ai.helperText.${subject_name}.function` },
      ],
    },
    {
      key: 'practice',
      showMobile: false,
      buttons: [
        { id: 9, label: `ai.helperText.${subject_name}.eksempel` },
        { id: 8, label: `ai.helperText.${subject_name}.7klasse` },
      ],
    },
  ];

  const renderButton = (id: number, label: string) => (
    <Grid item xs={12} key={id} container justifyContent="center">
      <Box width="100%" maxWidth="300px">
        <m.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          <Button
            color="inherit"
            onClick={() => {
              if (handleQuestionEmpty) {
                handleQuestionEmpty(id, String(translate(label)));
              } else {
                handleQuestion(String(translate(label)));
              }
              // setOpen(true);
            }}
            sx={{
              width: '100%',
              padding: '10px',
              backgroundColor: theme.palette.background.paper,
              border: theme.palette.borders.border,
              borderRadius: theme.palette.borders.borderRadius,
              boxShadow: theme.palette.borders.boxShadow,
            }}
            endIcon={<Iconify icon="ph:caret-right" />}
          >
            <Typography variant="body1">
              <Latex>{` ${String(translate(label))}`}</Latex>
            </Typography>
          </Button>
        </m.div>
      </Box>
    </Grid>
  );

  return (
    <LazyMotion features={domAnimation}>
      <Grid
        sx={{ maxWidth: isMobile ? '720px' : '1400px', mt: isMobile ? 0 : 1 }}
        container
        spacing={isMobile ? 2 : 3}
        direction={'row'}
        justifyContent={isMobile ? 'center' : 'space-evenly'}
        alignItems="center"
      >
        {sections
          .filter((section) => !isMobile || section.showMobile)
          .map((section) => (
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              key={section.key}
              container
              direction="column"
              alignItems="center"
              sx={{ mb: isMobile ? 4 : 0 }}
            >
              <Typography variant="h4" align="center" gutterBottom sx={{ mb: 2, mt: 2 }}>
                {String(translate(`ai.helperText.${section.key}`))}
              </Typography>

              <Grid container spacing={2} justifyContent="center" gap={isMobile ? 3 : 6}>
                {section.buttons.map((button) => renderButton(button.id, button.label))}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </LazyMotion>
  );
}
