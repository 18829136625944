import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IDocumentState, IDocument } from 'src/@types/documents';
import { AppDispatch, dispatch } from '../store';
import { updateFoldersAfterDocumentCreation } from './folders';
const initialState: IDocumentState = {
  isLoading: false,
  error: null,
  documents: [],
  selectedDocument: null,
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.log('Error occurred:', state.error);
    },
    // POST Document to database
    postDocumentSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.documents = [action.payload, ...state.documents];
    },
    // GET All Documents
    getDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },
    // GET Specific Document by ID
    getDocumentSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.selectedDocument = action.payload;
    },
    // PUT Updates onto document via ID
    updateDocumentSuccess(state, action) {
      const updatedDocument = action.payload;
      state.documents = state.documents.map((document: IDocument) =>
        document.id === updatedDocument.id
          ? { ...document, ...updatedDocument, name: updatedDocument.name }
          : document
      );
      if (state.selectedDocument && state.selectedDocument.id === updatedDocument.id) {
        state.selectedDocument = {
          ...state.selectedDocument,
          ...updatedDocument,
          name: updatedDocument.name,
        };
      }
    },
    // DELETE Document via ID
    deleteDocumentSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.documents = state.documents.filter((v) => v.id !== action.payload.id);
      if (state.selectedDocument && state.selectedDocument.id === action.payload.id) {
        state.selectedDocument = null;
      }
    },
    // Clear selected document
    clearSelectedDocument(state) {
      state.selectedDocument = null;
    },
  },
});

export default slice.reducer;

// Thunk actions
export function getDocuments(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_documents/${userId}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getDocumentsSuccess(response.data));
      return true;
    } catch (error) {
      console.error(error);
      let errorMessage = 'An unexpected error occurred';
      if (error.response) {
        if (error.response.data?.errors?.json?._schema) {
          errorMessage = error.response.data.errors.json._schema[0];
        } else if (error.response.data?.errors?.json) {
          const firstErrorKey = Object.keys(error.response.data.errors.json)[0];
          errorMessage = error.response.data.errors.json[firstErrorKey];
        } else {
          errorMessage = error.response.data.message || error.message;
        }
      } else {
        errorMessage = error.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getDocument(documentId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_document/${documentId}`, {
        withCredentials: true,
        responseType: 'blob',
      });

      const documentBlob = new Blob([response.data], { type: 'application/pdf' });

      const contentDisposition = response.headers['content-disposition'];

      if (!contentDisposition) {
        throw new Error('Content-Disposition header is missing');
      }
      const documentName = contentDisposition.split('filename=')[1];

      dispatch(
        slice.actions.getDocumentSuccess({ id: documentId, name: documentName, blob: documentBlob })
      );
      return true;
    } catch (error) {
      console.error(error);
      let errorMessage = 'An unexpected error occurred';
      if (error.response) {
        if (error.response.data?.errors?.json?._schema) {
          errorMessage = error.response.data.errors.json._schema[0];
        } else if (error.response.data?.errors?.json) {
          const firstErrorKey = Object.keys(error.response.data.errors.json)[0];
          errorMessage = error.response.data.errors.json[firstErrorKey];
        } else {
          errorMessage = error.response.data.message || error.message;
        }
      } else {
        errorMessage = error.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createDocument(userId: number, selectedFile: File, folderId?: number) {
  return async (
    dispatch: AppDispatch
  ): Promise<{ success: boolean; userId?: number; error?: any }> => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('user_id', userId.toString());

    if (folderId !== undefined) {
      formData.append('folder_id', folderId.toString());
    }

    try {
      console.log('Sending POST request to create document');
      const response = await axios.post('/api/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      console.log('Document created successfully:', response.data);
      if (response.data.folder_id) {
        dispatch(updateFoldersAfterDocumentCreation(response.data));
      } else {
        dispatch(slice.actions.postDocumentSuccess(response.data));
      }
      console.log('Fetching updated document list');
      const fetchResult = await dispatch(getDocuments(userId));

      if (fetchResult === true) {
        console.log('Document list updated successfully');
        return { success: true, userId };
      } else {
        console.error('Failed to fetch updated documents');
        throw new Error('Failed to fetch updated documents');
      }
    } catch (error) {
      console.error(error);
      let errorMessage = 'An unexpected error occurred';
      if (error.response?.data?.errors?.json?._schema) {
        errorMessage = error.response.data.errors.json._schema[0];
      } else if (error.response?.data?.errors?.json) {
        const firstErrorKey = Object.keys(error.response.data.errors.json)[0];
        errorMessage = error.response.data.errors.json[firstErrorKey];
      } else {
        errorMessage = error.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return { success: false, error };
    }
  };
}

export function editDocument(documentId: number, documentData: any) {
  return async (
    dispatch: Dispatch
  ): Promise<{ success: boolean; userId?: number; updatedDocument?: IDocument; error?: any }> => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/edit_document/${documentId}`, documentData, {
        withCredentials: true,
      });
      dispatch(slice.actions.updateDocumentSuccess(response.data));
      return { success: true, userId: response.data.user_id, updatedDocument: response.data };
    } catch (error) {
      console.error(error);
      let errorMessage = 'An unexpected error occurred';
      if (error.response?.data?.errors?.json?._schema) {
        errorMessage = error.response.data.errors.json._schema[0];
      } else if (error.response?.data?.errors?.json) {
        const firstErrorKey = Object.keys(error.response.data.errors.json)[0];
        errorMessage = error.response.data.errors.json[firstErrorKey];
      } else {
        errorMessage = error.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return { success: false, error };
    }
  };
}

export function deleteDocument(document: IDocument) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/delete_document/${document.id}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.deleteDocumentSuccess(response.data));

      return true;
    } catch (error) {
      console.error(error);
      let errorMessage = 'An unexpected error occurred';
      if (error.response?.data?.errors?.json?._schema) {
        errorMessage = error.response.data.errors.json._schema[0];
      } else if (error.response?.data?.errors?.json) {
        const firstErrorKey = Object.keys(error.response.data.errors.json)[0];
        errorMessage = error.response.data.errors.json[firstErrorKey];
      } else {
        errorMessage = error.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
