import { useTheme } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { IconButton } from '@mui/material';

type Props = {
  onClose: () => void;
  icon?: string;
  height?: number;
  width?: number;
  color?: string;
};
export default function Puller({ onClose, icon, height, width, color }: Props) {
  const theme = useTheme();
  return (
    <div
      style={{
        height: height ? height : 10,
        borderRadius: 3,
        marginLeft: 'calc(50% - 15px)',

        // position: 'absolute',
        // top: 8,
        // left: 'calc(50% - 15px)',
      }}
    >
      <IconButton sx={{ color: color ? color : 'black' }} onClick={() => onClose()}>
        <Iconify
          icon={icon ? icon : 'ph:arrows-in-line-vertical'}
          color={color ? color : 'black'}
          sx={{
            m: '0px',
            width: '1rem',
          }}
        ></Iconify>
      </IconButton>
    </div>
  );
}
